<script>
import ApiService from "@services/api.service";
import ImgCarousel from "@/components/ImgCarousel.vue";
import FileList from "@/components/FileList.vue";

export default {
  name: "LearningDetail",
  components: { ImgCarousel, FileList },
  props: {
    learningCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      learning: {
        code: null,
        description: null,
        learningCategoryCode: null,
        content: null,
        learningAttachments: []
      },
      parameter: {
        learningCategory: []
      }
    };
  },
  computed: {
    editMode() {
      return this.learningCode !== null;
    },
    fileList() {
      return this.learning.learningAttachments.filter(x => x.isImage === false);
    },
    imageList() {
      return this.learning.learningAttachments.filter(x => x.isImage === true);
    }
  },
  created() {
    if (this.learningCode) {
      this.loadLearning();
    }
    this.loadCategory();
  },
  methods: {
    loadLearning() {
      this.changeLoader(true);
      let qs = {
        code: this.learningCode
      };
      ApiService.get("learning/detail", qs)
        .then(resp => {
          this.learning = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },

    loadCategory() {
      this.changeLoader(true);
      ApiService.get("learning/category")
        .then(resp => {
          this.parameter.learningCategory = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },

    addCarousel(file) {
      this.changeLoader(true);
      let payload = {
        modelObject: {
          learningCode: this.learningCode,
          isImage: true,
          filename: file.filename
        },
        filePayload: file
      };
      ApiService.post("learning/attachment", payload)
        .then(() => {
          this.showToast("success", "Upload Image Successful");
          this.loadLearning();
        })
        .catch(error => {
          this.showToast("error", error);
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    addNewFile(file) {
      this.changeLoader(true);
      let payload = {
        modelObject: {
          learningCode: this.learningCode,
          isImage: false,
          filename: file.filename
        },
        filePayload: file
      };
      ApiService.post("learning/attachment", payload)
        .then(() => {
          this.showToast("success", "Upload File Successful");
          this.loadLearning();
        })
        .catch(error => {
          this.showToast("error", error);
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async removeCarousel(record) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: record.code
        };
        ApiService.delete("learning/attachment", qs)
          .then(() => {
            this.showToast("success", "Delete Successful");
            this.loadLearning();
          })

          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    async removeFile(record) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: record.code
        };
        ApiService.delete("learning/attachment", qs)
          .then(() => {
            this.showToast("success", "Delete File Successful");
            this.loadLearning();
          })

          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("learning", this.learning)
          .then(resp => {
            this.showToast("success", "Create Successful");
            this.$router.push({ name: "cms-learning-detail", params: { learningCode: resp.code } });
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        let payload = {
          code: this.learning.code,
          learningCategoryCode: this.learning.learningCategoryCode,
          description: this.learning.description
        };
        ApiService.put("learning", payload)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.$router.push({ name: "cms-learning-item" });
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="text-center">
          <h2>{{ !editMode ? `Create Learning` : `Edit Learning` }}</h2>
        </b-col>
      </b-row>
      <hr />

      <b-form @submit.stop.prevent="save">
        <b-row>
          <b-col md="4">
            <label>Category</label>
          </b-col>
          <b-col class="d-flex" md="8">
            <b-form-select
              v-model="learning.learningCategoryCode"
              :options="parameter.learningCategory"
              label="description"
              value-field="code"
              text-field="description"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col offset-md="4" md="8">
            <small>Learning group</small>
          </b-col>
        </b-row>
        <b-row class="mb-2 mt-2">
          <b-col md="4">
            <label>Description</label>
          </b-col>
          <b-col md="8">
            <b-input v-model="learning.description" class="form-control" />
            <small>Learning Description</small>
          </b-col>
        </b-row>

        <b-row class="d-flex ml-auto mt-5">
          <b-col>
            <router-link :to="{ name: 'cms-learning-item' }">
              <b-btn block variant="danger">Cancel</b-btn>
            </router-link>
          </b-col>
          <b-col>
            <b-btn block type="submit" variant="success">Save</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-row v-if="editMode">
      <b-col md="4">
        <FileList :items="fileList" field="name" @onRemove="removeFile" @onFileChanged="addNewFile" />
      </b-col>
      <b-col md="8">
        <ImgCarousel :items="imageList" field="url" @onRemove="removeCarousel" @onFileChanged="addCarousel" />
      </b-col>
    </b-row>
  </div>
</template>
