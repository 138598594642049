<script>
export default {
  props: {
    items: {
      required: true,
      type: Array
    },
    field: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    newFile: null
  }),
  methods: {
    remove(record) {
      this.$emit(`onRemove`, record);
    },
    fileChanged(event) {
      let file = {
        filename: event.target.files[0].name
      };
      var reader = new FileReader();
      reader.onloadend = () => {
        file.base64File = reader.result;
        this.$emit(`onFileChanged`, file);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
};
</script>

<template>
  <b-card>
    <b-card-title>
      <b-row>
        <b-col class="text-center">
          <h2>Attachments</h2>
        </b-col>
      </b-row>
      <hr />
      <b-form-file
        v-model="newFile"
        class="ml-auto form=control"
        placeholder="More file attachment..."
        drop-placeholder="Drop attachment here..."
        @change="fileChanged"
      ></b-form-file>
    </b-card-title>
    <b-list-group>
      <b-list-group-item v-for="(x, i) in items" :key="i" class="d-flex align-items-center">
        <span>{{ x.filename }}</span>
        <b-button class="ml-auto" variant="danger" size="sm" @click.stop="remove(x)">
          <feather-icon icon="DeleteIcon" />
        </b-button>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
